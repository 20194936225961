.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: black;
  padding-top: 2rem;
}

.carousel-wrapper,
.videos-wrapper {
  width: 100%;
  margin: 0;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  height: 400px;
}

.carousel-item img,
.video-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.video-thumbnail-wrapper {
  position: relative;
  width: 100%;
  height: 400px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.video-icon-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.play-icon,
.close-icon {
  color: white;
  cursor: pointer;
}

.play-icon {
  font-size: 3rem;
}

.video-title {
  color: white;
  font-size: 1.5rem;
  padding-left: 20px;
}

.video-fullscreen-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1010;
}

@media (min-width: 768px) {
  .gallery {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .carousel-images-wrapper, .testimonios-wrapper {
    width: calc(50% - 1rem);
    margin: 0.5rem;
    height: auto;
  }

  .carousel-video-wrapper {
    width: 100%;
    margin: 0.5rem;
    height: auto;
  }
}

@media (min-width: 1024px) {
  .gallery {
    padding: 2rem 1rem 0 7rem;
  }

  .carousel-images-wrapper,
  .testimonios-wrapper {
    width: calc(50% - 1rem);
    height: auto;
    margin-top: 1rem;
  }

  .carousel-video-wrapper {
    width: 100%;
    height: auto;
    margin-top: 1rem;
  }
}

@media (max-width: 767px) {
  .gallery {
    padding: 1rem;
  }

  .carousel-images-wrapper,
  .testimonios-wrapper,
  .carousel-video-wrapper {
    width: 100%;
    height: auto;
    margin-top: 1rem;
  }

  .carousel-item,
  .video-thumbnail-wrapper {
    height: 300px;
  }
}
