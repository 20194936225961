/* src/styles/fonts.css */

/* Definición de fuentes con @font-face para Martel Sans */
@font-face {
    font-family: 'Martel Sans';
    src: url('../fonts/Martel_Sans/MartelSans-Bold.woff2') format('woff2'),
         url('../fonts/Martel_Sans/MartelSans-Bold.woff') format('woff'),
         url('../fonts/Martel_Sans/MartelSans-Bold.ttf') format('truetype');
    font-weight: 700; /* Bold */
    font-style: normal;
    font-display: swap;
}

/* Definición de fuentes con @font-face para Inter */
@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Regular.woff2') format('woff2'),
         url('../fonts/Inter/Inter-Regular.woff') format('woff'),
         url('../fonts/Inter/static/Inter-Regular.ttf') format('truetype');
    font-weight: 400; /* Normal */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-Bold.woff2') format('woff2'),
         url('../fonts/Inter/Inter-Bold.woff') format('woff'),
         url('../fonts/Inter/static/Inter-Bold.ttf') format('truetype');
    font-weight: 700; /* Bold */
    font-style: normal;
    font-display: swap;
}
