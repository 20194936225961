.sidebar {
  display: none;
}

@media screen and (min-width: 1024px) {
  .sidebar {
    border-right: 2px solid grey;
    height: 100vh;
    width: 6rem;
    position: fixed;
    top: 0;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .sidebarLayout {
    height: 53%;
    width: 8%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: fixed;
  }

  .logoDesktop {
    height: 7rem;
    width: 6rem;
  }
}
