@keyframes menuFade {
  0% {
    opacity: 0.8;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes menuFadeLeft {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0.8;
    transform: translateX(-100%);
  }
}

.hiddenMenu {
  display: none;
}

.burgerMenu,
.closeBurgerMenu {
  width: 100%;
  height: 100%;
  background-color: black;
  position: fixed;
  top: 0;
  z-index: 4;
  animation-duration: 500ms;
  overflow: hidden;
}

.burgerMenu {
  animation-name: menuFade;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeBurgerMenu {
  animation-name: menuFadeLeft;
  animation-fill-mode: forwards;
}

.itemsMenuWrap {
  width: 20rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.itemsMenu {
  width: 13rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: -6rem;
}

.burgerMenu .itemsMenuWrap,
.closeBurgerMenu .itemsMenuWrap {
  .itemsMenu h2 {
    font-size: 2.5rem;
    padding: 1.5rem;
  }
}

.itemsMenu a {
  text-decoration: none;
  color: inherit;
}

.itemsMenu a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.redes {
  width: 98%;
  height: 6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.iconos {
  width: 12rem;
  height: 3.4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.logoMenu {
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.closeLogo {
  height: 3rem;
  width: 6rem;
}

.logoMargin {
  margin-left: 1.2rem;
}

@media screen and (min-width: 768px) {
  .burgerMenu {
    justify-content: center;
  }

  .burgerMenu .itemsMenuWrap,
  .closeBurgerMenu .itemsMenuWrap {
    .itemsMenu h2 {
      font-size: 4.5rem;
    }
  }

  .itemsMenuWrap {
    width: 25rem;
    margin-top: 0;
    justify-content: center;
    align-items: center;
  }

  .itemsMenu {
    width: 25rem;
    height: 38rem;
    margin-bottom: 0;
  }

  .redes {
    width: 90%;
    height: 3.5rem;
  }

  .iconos {
    width: 15.5rem;
  }
}
