.aboutUsContainer {
  background-color: black;
  color: white;
  min-height: auto;
  width: 100%;
  padding: 1.25rem;
  text-align: center;
}

.title::before {
  content: '';
  display: block;
  height: 0.7rem;
  width: 100%;
  background: linear-gradient(to right, #ff9240, #ff3e7a);
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.textSection {
  width: 100%;
  padding-bottom: 1rem;
  text-align: left;
}

.imagesSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
}

.fotoLuchiMika, .fotoMika, .fotoLuchi {
  height: 7rem;
  width: 7rem;
}

.additionalText {
  overflow: hidden;
  max-height: 0;
  transition: max-height 1s ease-in-out;
  margin-top: 1rem;
}

.open {
  max-height: 350px;
}

@media (min-width: 768px) {
  .aboutUsContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .textSection {
    width: 100%;
  }

  .imagesSection {
    justify-content: space-around;
    width: 100%;
    padding-top: 2rem;
  }

  .fotoLuchiMika, .fotoMika, .fotoLuchi {
    height: 12rem;
    width: 12rem;
  }

  .open {
    max-height: 150px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .imagesSection {
    flex-direction: row;
  }
  
  .fotoMika {
    order: 1;
  }

  .fotoLuchiMika {
    order: 2;
  }

  .fotoLuchi {
    order: 3;
  }
}

@media (min-width: 1024px) {
  .aboutUsContainer {
    display: flex;
    flex-direction: row;
    min-height: 70vh;
    padding-left: 12rem;
  }

  .textSection {
    width: 60%;
    text-align: left;
    padding-top: 4rem;
  }

  .open {
    max-height: 150px;
  }

  .imagesSection {
    width: 40%;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    flex-wrap: wrap;
  }
  
  .fotoLuchiMika {
    width: 16rem;
    height: 16rem;
  }

  .fotoMika {
    width: 15rem;
    height: 15rem;
    align-self: flex-end;
  }

  .fotoLuchi {
    width: 15rem;
    height: 15rem;
  }
}

@media (max-width: 767px) {
  .imagesSection {
    justify-content: center;
  }

  .fotoLuchiMika, .fotoMika {
    display: none;
  }

  .imagesSection .fotoLuchi {
    height: 12rem;
    width: 94%;
    border-radius: 51%;
  }
}