@import url('./styles/fonts.css');

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: 'Martel Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global Styles */
* {
  margin: 0;
  box-sizing: border-box;
  color: white;
}

/* Animations */
@keyframes fade {
  0% {
    opacity: 0;
    margin-top: 6rem;
  }
  100% {
    opacity: 1;
  }
}
