.servicios-container {
  background-color: black;
  color: white;
  padding: 1rem 1rem 2rem;
  box-sizing: border-box;
}

.titulo-servicios {
  text-align: center;
  margin: 3rem 0;
  font-size: 3.1rem;
}

.listado-servicios {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.item-servicio {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  padding-bottom: 1rem;
}

.item-servicio h2 {
  line-height: 2.2;
  font-size: 2rem;
}

.linea-decorativa {
  content: '';
  width: 15rem;
  height: 2px;
  background: linear-gradient(to right, #ff9240, #ff3e7a);
  margin-top: 0.5rem;
}

@media (min-width: 768px) {
  .titulo-servicios {
    text-align: center;
  }

  .listado-servicios {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1024px) {
  .servicios-container {
    padding: 1rem 6rem 2rem 12rem;
  }

  .titulo-servicios {
    text-align: left;
    margin: 0;
    font-size: 3.1rem;
  }

  .listado-servicios {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .item-servicio {
    align-items: flex-start;
  }

  .item-servicio h2 {
    font-size: 2rem;
  }
}
