.containerLanding {
    height: 100vh;
    width: 100%;
}

body {
    font-family: 'Inter', sans-serif;
  }

h1 {
    font-family: 'Martel Sans', sans-serif;
  }