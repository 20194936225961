.landingPage {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  transition: 0.5s;
  background-color: black;
  z-index: -2;
}

.videoBg {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: -1;
  object-fit: cover;
}

.bgOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  z-index: 0;
}

.landingLayout {
  z-index: 3;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landingText {
  width: 87%;
  height: 100%;
  z-index: 2;
  margin: -4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 786px) {
  .landingText {
    width: 80%;
  }
}

.overlayText {
  color: grey;
  font-size: 1rem;
  height: 1.5rem;
  margin-bottom: -1.5rem;
}

@media screen and (min-width: 786px) {
  .overlayText {
    font-size: 1.2rem;
    margin-bottom: -2.5rem;
  }
}

.mainText {
  font-size: 1.3rem;
}

@media screen and (min-width: 786px) {
  .mainText {
    font-size: 2.5rem;
  }
}

.title {
  font-size: 5rem;
  height: 7rem;
}

@media screen and (min-width: 786px) {
  .title {
    font-size: 9rem;
    height: 13rem;
  }
}

.loaderWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #FF8F00; 
  border-right: 16px solid;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.videoFrame {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: -1;
  object-fit: cover;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
